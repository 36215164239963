import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, matchRoutes } from 'react-router-dom';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useImaginedFuture } from 'hooks/useImaginedFuture';
import { useNavMenu } from 'hooks/useNavMenu';

import { checkIsProfileComplete } from 'libs/utils';

import { AvatarList } from 'components/ImageSelector';
import FeedbackTool from 'components/FeedbackTool';

import GrandLogoGreen from 'assets/grand-logo-green-horz.svg';
import HamburgerMenuLines from 'assets/hamburger-menu-lines.svg';
import X from 'assets/x.svg';

import { Fonts, Overlays, Spacing } from 'styles/theme';
import {
    CloseButtonWrapper,
    Divider,
    FooterLinkWrapper,
    LinkWrapper,
    MenuButton,
    NavHeaderWrapper,
    NavMenu,
    NavMenuInnerWrapper,
    NavSection,
    SignOutWrapper,
    StyledInternalLink,
    StyledExternalLink,
    Footer,
    InnerHeader,
    ProfileDetailsWrapper,
    AvatarImage,
    AvatarImageWrapper,
    OuterLogo,
} from 'styles/navHeader';
import { createPortal } from 'react-dom';

function NavHeader() {
    const location = useLocation();
    const sessionCoachRouteMatch = matchRoutes(
        [{ path: '/sessions' }, { path: '/coach' }],
        location
    );
    const { isSafari } = useBrowserData();
    const { isSignedIn, signOut, userId, userProfile } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { imaginedFuture } = useImaginedFuture();
    const { setIsNavMenuOpen } = useNavMenu();

    const [isInternalNavMenuOpen, setIsInternalNavMenuOpen] =
        useState<boolean>(false);
    const [isFeedbackToolOpen, setIsFeedbackToolOpen] =
        useState<boolean>(false);

    const userProfileParsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(userProfile?.raw_data ?? {})),
        [userProfile?.raw_data]
    );

    const onOpenMenu = useCallback(() => {
        setIsInternalNavMenuOpen(true);
        setIsNavMenuOpen?.(true);

        sendEvent?.('main_nav_menu_opened');
    }, [sendEvent, setIsNavMenuOpen]);

    const onCloseMenu = useCallback(() => {
        setIsInternalNavMenuOpen(false);
        setIsNavMenuOpen?.(false);
    }, [setIsNavMenuOpen]);

    const onSelectProfile = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_profile_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectRetrospectives = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_retros_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectClosings = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_closings_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectGroup = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_group_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectImaginedFuture = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_imagined_future_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectFeedbackTool = useCallback(() => {
        setIsFeedbackToolOpen(true);
        onCloseMenu();

        sendEvent?.('main_nav_menu_feedback_selected');
    }, [onCloseMenu, sendEvent]);

    const onCloseFeedbackTool = useCallback(() => {
        setIsFeedbackToolOpen(false);
    }, []);

    const onSelectGrandCompass = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_grand_compass_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectGrandCat = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_grand_cat_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectMemberLibrary = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_member_library_selected');
    }, [onCloseMenu, sendEvent]);

    const onSelectSlack = useCallback(() => {
        onCloseMenu();

        sendEvent?.('main_nav_menu_slack_selected');
    }, [onCloseMenu, sendEvent]);

    const onSignOut = useCallback(async () => {
        if (isSignedIn) {
            await signOut?.();
            setIsNavMenuOpen?.(false);

            sendEvent?.('logout');
        }
    }, [isSignedIn, sendEvent, setIsNavMenuOpen, signOut]);

    return (
        <NavHeaderWrapper>
            {sessionCoachRouteMatch != null ? (
                <Link
                    to="/sessions"
                    onClick={onCloseMenu}
                    aria-label="session-list-link"
                >
                    <OuterLogo src={GrandLogoGreen} alt="session-list" />
                </Link>
            ) : (
                <div />
            )}

            <MenuButton onClick={onOpenMenu} aria-label="open-menu">
                <img
                    src={HamburgerMenuLines}
                    width={28}
                    alt="main-menu-image"
                />
            </MenuButton>

            {!isSafari || isInternalNavMenuOpen ? (
                <>
                    <Overlays.BackgroundOverlay
                        onClick={onCloseMenu}
                        $isOpen={isInternalNavMenuOpen}
                    />
                    <NavMenu $isOpen={isInternalNavMenuOpen}>
                        <InnerHeader>
                            <CloseButtonWrapper
                                onClick={onCloseMenu}
                                aria-label="close-menu"
                            >
                                <img src={X} width={36} aria-label="x-image" />
                            </CloseButtonWrapper>
                        </InnerHeader>

                        <NavMenuInnerWrapper>
                            <div>
                                <NavSection>
                                    <Link
                                        to={`/profiles/${
                                            userProfile?.is_coach
                                                ? userProfile.coach_groups?.[0]
                                                : userProfile
                                                      ?.member_groups?.[0]
                                        }/${userId}`}
                                        onClick={onSelectProfile}
                                        aria-label="my-profile-link"
                                    >
                                        <ProfileDetailsWrapper>
                                            <AvatarImageWrapper>
                                                <AvatarImage
                                                    src={
                                                        AvatarList[
                                                            userProfileParsedRawData?.avatar ??
                                                                0
                                                        ]
                                                    }
                                                />
                                            </AvatarImageWrapper>
                                            <div>
                                                <Fonts.BodyTextBold $color="light">
                                                    {userProfile?.full_name}
                                                </Fonts.BodyTextBold>
                                                <Fonts.SmallText $color="primary-alt">
                                                    {checkIsProfileComplete(
                                                        userProfileParsedRawData
                                                    )
                                                        ? `View Profile`
                                                        : `Complete your profile!`}
                                                </Fonts.SmallText>
                                            </div>
                                        </ProfileDetailsWrapper>
                                    </Link>
                                    <Spacing.Bumper />
                                    <LinkWrapper>
                                        <StyledInternalLink
                                            to={
                                                userProfile?.is_coach
                                                    ? '/coach'
                                                    : '/sessions'
                                            }
                                            onClick={onCloseMenu}
                                            aria-label={
                                                userProfile?.is_coach
                                                    ? 'coach-recaps-home-link'
                                                    : 'session-list-link'
                                            }
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`Home`}
                                            </Fonts.BodyText>
                                        </StyledInternalLink>
                                    </LinkWrapper>

                                    {userProfile?.is_coach ? (
                                        <>
                                            <LinkWrapper>
                                                <StyledInternalLink
                                                    to="/recap-history"
                                                    onClick={onCloseMenu}
                                                    aria-label="my-submitted-recaps-link"
                                                >
                                                    <Fonts.BodyText $color="light">{`My Submitted Recaps`}</Fonts.BodyText>
                                                </StyledInternalLink>
                                            </LinkWrapper>
                                            <LinkWrapper>
                                                <Fonts.BodyText
                                                    as="span"
                                                    $color="light"
                                                >{`My Groups:`}</Fonts.BodyText>
                                                {userProfile?.coach_groups?.map(
                                                    (groupId) => (
                                                        <StyledInternalLink
                                                            key={groupId}
                                                            to={`/profiles/${groupId}`}
                                                            onClick={
                                                                onCloseMenu
                                                            }
                                                            aria-label="group-profiles-link"
                                                        >
                                                            <Fonts.BodyText
                                                                as="span"
                                                                $color="light"
                                                            >{` ${groupId}`}</Fonts.BodyText>
                                                        </StyledInternalLink>
                                                    )
                                                )}
                                            </LinkWrapper>
                                        </>
                                    ) : null}

                                    {userProfile?.is_coach ? null : (
                                        <>
                                            <LinkWrapper>
                                                <StyledInternalLink
                                                    to="/retrospectives"
                                                    onClick={
                                                        onSelectRetrospectives
                                                    }
                                                    aria-label="retrospectives-link"
                                                >
                                                    <Fonts.BodyText $color="light">
                                                        {`The Grand Retro`}
                                                    </Fonts.BodyText>
                                                </StyledInternalLink>
                                            </LinkWrapper>
                                            <LinkWrapper>
                                                <StyledInternalLink
                                                    to="/closings"
                                                    onClick={onSelectClosings}
                                                    aria-label="closings-link"
                                                >
                                                    <Fonts.BodyText $color="light">
                                                        {`Session Closings`}
                                                    </Fonts.BodyText>
                                                </StyledInternalLink>
                                            </LinkWrapper>
                                            {imaginedFuture === '' ? null : (
                                                <LinkWrapper>
                                                    <StyledInternalLink
                                                        to="/imagined-future"
                                                        onClick={
                                                            onSelectImaginedFuture
                                                        }
                                                        aria-label="imagined-future-link"
                                                    >
                                                        <Fonts.BodyText $color="light">
                                                            {`Imagined Future`}
                                                        </Fonts.BodyText>
                                                    </StyledInternalLink>
                                                </LinkWrapper>
                                            )}
                                            {userProfile?.is_active === true &&
                                            userProfile?.member_groups?.[0] !==
                                                null ? (
                                                <LinkWrapper>
                                                    <StyledInternalLink
                                                        to={`/profiles/${userProfile?.member_groups?.[0]}`}
                                                        onClick={onSelectGroup}
                                                        aria-label="group-profiles-link"
                                                    >
                                                        <Fonts.BodyText $color="light">
                                                            {`My Group (${userProfile?.member_groups?.[0]})`}
                                                        </Fonts.BodyText>
                                                    </StyledInternalLink>
                                                </LinkWrapper>
                                            ) : null}
                                        </>
                                    )}
                                </NavSection>
                                <NavSection>
                                    <Fonts.SmallCapsText $color="light-orange">{`Tools`}</Fonts.SmallCapsText>
                                    <LinkWrapper>
                                        <StyledExternalLink
                                            as="div"
                                            onClick={onSelectFeedbackTool}
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`Feedback Tool`}
                                            </Fonts.BodyText>
                                        </StyledExternalLink>
                                    </LinkWrapper>
                                    <LinkWrapper>
                                        <StyledExternalLink
                                            href="https://compass.thegrand.world/"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={onSelectGrandCompass}
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`The Grand Compass`}
                                            </Fonts.BodyText>
                                        </StyledExternalLink>
                                    </LinkWrapper>
                                    <LinkWrapper>
                                        <StyledExternalLink
                                            href="https://cat.thegrand.world/"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={onSelectGrandCat}
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`The Grand Cat`}
                                            </Fonts.BodyText>
                                        </StyledExternalLink>
                                    </LinkWrapper>
                                </NavSection>
                                <NavSection>
                                    <Fonts.SmallCapsText $color="light-orange">{`Resources`}</Fonts.SmallCapsText>
                                    {userProfile?.is_coach ? (
                                        <LinkWrapper>
                                            <StyledExternalLink
                                                href="https://www.notion.so/thegrandworld/The-Grand-Coaching-Hub-7fef7bc9ada5414ab03e35cc6cd1577c"
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={onCloseMenu}
                                            >
                                                <Fonts.BodyText $color="light">{`Coaches Hub (Notion)`}</Fonts.BodyText>
                                            </StyledExternalLink>
                                        </LinkWrapper>
                                    ) : null}
                                    <LinkWrapper>
                                        <StyledExternalLink
                                            href="https://tools.thegrand.world/library"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={onSelectMemberLibrary}
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`Member Learning Library`}
                                            </Fonts.BodyText>
                                        </StyledExternalLink>
                                    </LinkWrapper>
                                    <LinkWrapper>
                                        <StyledExternalLink
                                            href="https://thegrandcommuity.slack.com"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={onSelectSlack}
                                        >
                                            <Fonts.BodyText $color="light">
                                                {`Slack Community`}
                                            </Fonts.BodyText>
                                        </StyledExternalLink>
                                    </LinkWrapper>
                                </NavSection>
                                <Divider />
                                <SignOutWrapper
                                    onClick={onSignOut}
                                    role="button"
                                    aria-label="sign-out"
                                >
                                    <Fonts.BodyText $color="light">{`Sign Out`}</Fonts.BodyText>
                                </SignOutWrapper>
                            </div>

                            <Footer>
                                <FooterLinkWrapper>
                                    <StyledExternalLink
                                        href="https://www.thegrand.world/termsofservice"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={onCloseMenu}
                                    >
                                        <Fonts.FinePrintText $color="light">
                                            {`Terms of Service`}
                                        </Fonts.FinePrintText>
                                    </StyledExternalLink>
                                </FooterLinkWrapper>
                                <FooterLinkWrapper>
                                    <StyledExternalLink
                                        href="https://www.thegrand.world/privacypolicy"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={onCloseMenu}
                                    >
                                        <Fonts.FinePrintText $color="light">
                                            {`Privacy Policy`}
                                        </Fonts.FinePrintText>
                                    </StyledExternalLink>
                                </FooterLinkWrapper>
                            </Footer>
                        </NavMenuInnerWrapper>
                    </NavMenu>
                </>
            ) : null}

            {createPortal(
                <Overlays.ModalOuterWrapper $isOpen={isFeedbackToolOpen}>
                    <Overlays.BackgroundOverlay
                        onClick={onCloseFeedbackTool}
                        $isOpen={isFeedbackToolOpen}
                    />
                    <Overlays.ModalInnerWrapper $shouldFillScreen>
                        <FeedbackTool onClose={onCloseFeedbackTool} />
                    </Overlays.ModalInnerWrapper>
                </Overlays.ModalOuterWrapper>,
                document.getElementById('app-wrapper') ?? document.body
            )}
        </NavHeaderWrapper>
    );
}

export default NavHeader;
