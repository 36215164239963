import { Learnings } from 'components/Sessions';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAnalytics } from 'hooks/useAnalytics';

import X from 'assets/x-black.svg';
import CaretDown from 'assets/caret-down.svg';

import { Forms, Fonts, Overlays, Spacing } from 'styles/theme';
import {
    AlternateDropdownOption,
    BulletList,
    BulletListItem,
    BulletText,
    Dropdown,
    DropdownCaret,
    DropdownOption,
    SessionOverviewWrapper,
    YourLearningBoldText,
} from 'styles/sessionOverview';

interface SessionOverviewProps {
    sessionNumber: string;
    sessionDate: string;
    sessionObjectiveTitle: string;
    sessionObjectives: Array<string>;
    sessionGroupLearnings: Learnings;
    sessionKudosCounts: Map<string, number>;
    onClose: () => void;
    isOnlyLearnings: boolean;
}

function SessionOverview(props: SessionOverviewProps) {
    const {
        sessionNumber,
        sessionDate,
        sessionObjectiveTitle,
        sessionObjectives,
        sessionGroupLearnings,
        sessionKudosCounts,
        onClose,
        isOnlyLearnings,
    } = props;

    const { sendEvent } = useAnalytics();

    const [selectedTab, setSelectedTab] = useState<'objectives' | 'learnings'>(
        'objectives'
    );
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const sessionDateObject = useMemo(
        () => new Date(sessionDate),
        [sessionDate]
    );

    useEffect(() => {
        if (isOnlyLearnings || sessionGroupLearnings.length > 0) {
            setSelectedTab('learnings');
        } else {
            setSelectedTab('objectives');
        }
    }, [isOnlyLearnings, sessionGroupLearnings.length]);

    const onSelectTab = useCallback(
        (tab: 'objectives' | 'learnings') => () => {
            setSelectedTab(tab);

            if (tab === 'learnings') {
                sendEvent?.('session_learnings_opened', {
                    session_number: sessionNumber,
                    session_objective_title: sessionObjectiveTitle,
                });
            }
        },
        [sendEvent, sessionNumber, sessionObjectiveTitle]
    );

    const internalOnClose = useCallback(() => {
        onClose();

        sendEvent?.('session_overview_closed', {
            session_number: sessionNumber,
            session_objective_title: sessionObjectiveTitle,
        });
    }, [onClose, sendEvent, sessionNumber, sessionObjectiveTitle]);

    const kudosCountsMap = useMemo(() => {
        const kudosCountsToRender: Array<React.ReactNode> = [];
        sessionKudosCounts.forEach((kudosCount, kudosText) => {
            kudosCountsToRender.push(
                <>
                    <Fonts.BodyTextBold as="span">
                        {kudosText}
                    </Fonts.BodyTextBold>
                    {` (x${kudosCount.toString()})`}
                    {kudosCountsToRender.length < sessionKudosCounts.size - 1
                        ? ', '
                        : ''}
                </>
            );
        });
        return kudosCountsToRender;
    }, [sessionKudosCounts]);

    return (
        <>
            <Forms.CloseButtonWrapper onClick={internalOnClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Spacing.Bumper />
            {isOnlyLearnings ? null : (
                <Dropdown
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    $isDropdownOpen={isDropdownOpen}
                >
                    <DropdownOption $isDropdownOpen={isDropdownOpen}>
                        <Fonts.SmallCapsText $color="orange">
                            {selectedTab}
                        </Fonts.SmallCapsText>
                        <DropdownCaret
                            src={CaretDown}
                            $isDropdownOpen={isDropdownOpen}
                        />
                    </DropdownOption>
                    <AlternateDropdownOption
                        onClick={onSelectTab(
                            selectedTab === 'objectives'
                                ? 'learnings'
                                : 'objectives'
                        )}
                        $isDropdownOpen={isDropdownOpen}
                    >
                        <Fonts.SmallCapsText>
                            {selectedTab === 'objectives'
                                ? 'learnings'
                                : 'objectives'}
                        </Fonts.SmallCapsText>
                    </AlternateDropdownOption>
                </Dropdown>
            )}

            <SessionOverviewWrapper $removeMargin={isOnlyLearnings}>
                <Overlays.ModalInnerScroller>
                    {isOnlyLearnings ? (
                        <Fonts.LargeText>
                            {`${sessionDateObject.toLocaleString('en-us', {
                                month: 'long',
                            })}
                            ${sessionDateObject.getDate()}, ${sessionDateObject.getFullYear()}`}
                        </Fonts.LargeText>
                    ) : (
                        <Fonts.SmallText>
                            {`Session ${sessionNumber}`}
                        </Fonts.SmallText>
                    )}

                    {selectedTab === 'objectives' ? (
                        <>
                            <Fonts.Heading4>
                                {sessionObjectiveTitle}
                            </Fonts.Heading4>
                            <BulletList>
                                {sessionObjectives.map?.(
                                    (sessionPoint: string) => (
                                        <BulletListItem key={sessionPoint}>
                                            <BulletText>
                                                {sessionPoint}
                                            </BulletText>
                                            <Spacing.Bumper />
                                        </BulletListItem>
                                    )
                                )}
                            </BulletList>
                        </>
                    ) : null}

                    {selectedTab === 'learnings' ? (
                        <>
                            {isOnlyLearnings ? (
                                <Fonts.Heading3>{`Your group learnings`}</Fonts.Heading3>
                            ) : (
                                <Fonts.Heading4>
                                    {sessionGroupLearnings.length === 0
                                        ? `Your group learnings will appear after session ends`
                                        : `Your group learnings`}
                                </Fonts.Heading4>
                            )}
                            {sessionGroupLearnings.length === 0 ? (
                                <>
                                    <Spacing.Bumper />
                                    <Fonts.BodyText>{`Please come back after your session is finished!`}</Fonts.BodyText>
                                </>
                            ) : (
                                <>
                                    <BulletList>
                                        {sessionGroupLearnings.map?.(
                                            (groupLearning) => (
                                                <BulletListItem
                                                    key={groupLearning.learning}
                                                >
                                                    {groupLearning.isUserLearning ? (
                                                        <YourLearningBoldText>{`Your learning: `}</YourLearningBoldText>
                                                    ) : null}
                                                    <BulletText>{`“${groupLearning.learning}”`}</BulletText>
                                                </BulletListItem>
                                            )
                                        )}
                                    </BulletList>
                                    {sessionKudosCounts.size > 0 ? (
                                        <>
                                            <Spacing.Bumper />
                                            <Spacing.Bumper />
                                            <Fonts.Heading6>{`Members in your group gave you the following kudos:`}</Fonts.Heading6>
                                            <Fonts.BodyText>
                                                {`You were recognized for being `}
                                                {kudosCountsMap}
                                            </Fonts.BodyText>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : null}
                </Overlays.ModalInnerScroller>
            </SessionOverviewWrapper>
        </>
    );
}

export default SessionOverview;
