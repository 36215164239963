import { ProfileData } from 'hooks/useSupabase';

import React, { useMemo } from 'react';

import { useSupabase } from 'hooks/useSupabase';

import { AvatarList } from 'components/ImageSelector';

import { Buttons, Fonts, Spacing } from 'styles/theme';
import {
    AvatarImage,
    AvatarImageWrapper,
    ProfileLink,
    ProfileListWrapper,
} from 'styles/groupProfiles';
import { checkIsProfileComplete } from 'libs/utils';

interface GroupProfilesProps {
    groupId: string;
    groupProfiles: Array<ProfileData>;
}

function GroupProfiles(props: GroupProfilesProps) {
    const { groupId, groupProfiles } = props;

    const { userId } = useSupabase();

    const membersToRawData = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const memberDataMapping = {} as any;
        groupProfiles.forEach((profile) => {
            const parsedRawData = JSON.parse(
                JSON.stringify(profile.raw_data ?? {})
            );
            memberDataMapping[profile.id] = {
                parsedRawData,
                isProfileComplete: checkIsProfileComplete(parsedRawData),
            };
        });
        return memberDataMapping;
    }, [groupProfiles]);

    return (
        <>
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.Heading3>{`My Group (${groupId})`}</Fonts.Heading3>
            <Spacing.Bumper />
            <ProfileListWrapper>
                {groupProfiles.map((profile) => {
                    if (profile.is_active !== true) {
                        return null;
                    }

                    const isProfileComplete =
                        membersToRawData[profile.id]?.isProfileComplete ??
                        false;
                    return (
                        <ProfileLink
                            key={profile.id}
                            to={`/profiles/${groupId}/${profile.id}`}
                            $isProfileAccessible={
                                isProfileComplete || userId === profile.id
                            }
                        >
                            <Buttons.OverlayMedium />
                            <AvatarImageWrapper>
                                <AvatarImage
                                    src={
                                        AvatarList[
                                            membersToRawData[profile.id]
                                                ?.parsedRawData?.avatar ?? 0
                                        ]
                                    }
                                />
                            </AvatarImageWrapper>
                            <div>
                                <Fonts.Heading5>
                                    {profile.full_name}
                                </Fonts.Heading5>
                                <Fonts.SmallText>
                                    {isProfileComplete
                                        ? membersToRawData[profile.id]
                                              ?.parsedRawData?.headline
                                        : userId === profile.id
                                        ? 'Complete your profile!'
                                        : '(Profile not complete)'}
                                </Fonts.SmallText>
                            </div>
                        </ProfileLink>
                    );
                })}
            </ProfileListWrapper>
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Spacing.Bumper />
        </>
    );
}

export default GroupProfiles;
