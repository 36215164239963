import React, { useCallback, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import {
    INTAKE_TIMESLOT_SPONSORS,
    INTAKE_TIMESLOT_OPTIONS,
    YEARS_EXPERIENCE_OPTIONS,
} from 'libs/constants';

import { AuthenticateRoute } from 'components/AppRoutes';
import TextInput from 'components/TextInput';
import RequiredAsterisk from 'components/RequiredAsterisk';
import LoadingButton from 'components/LoadingButton';
import SelectInput from 'components/SelectInput';

import { Fonts, Inputs, Forms, Spacing } from 'styles/theme';
import { TimeSlotWrapper } from 'styles/submitIntake';

type YearsExperienceOptions = (typeof YEARS_EXPERIENCE_OPTIONS)[number];

interface SubmitIntakeProps {
    onSubmit: () => Promise<void>;
}

function SubmitIntake(props: SubmitIntakeProps) {
    const { onSubmit } = props;

    const { supabase, userId, userProfile, userEmail } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(userProfile?.raw_data ?? {})),
        [userProfile?.raw_data]
    );

    const [linkedIn, setLinkedIn] = useState<string>('');
    const [country, setCountry] = useState<string>(
        parsedRawData?.country ?? ''
    );
    const [state, setState] = useState<string>(parsedRawData?.state ?? '');
    const [city, setCity] = useState<string>(parsedRawData?.city ?? '');
    const [title, setTitle] = useState<string>(parsedRawData?.headline ?? '');
    const [yearsExperience, setYearsExperience] =
        useState<YearsExperienceOptions | null>(null);
    const [imaginedFuture, setImaginedFuture] = useState<string>(
        userProfile?.imagined_future ?? ''
    );
    const [hasSelectedWednesdayTime1, setHasSelectedWednesdayTime1] =
        useState<boolean>(false);
    const [hasSelectedWednesdayTime2, setHasSelectedWednesdayTime2] =
        useState<boolean>(false);
    const [hasSelectedFridayTime1, setHasSelectedFridayTime1] =
        useState<boolean>(false);
    const [hasSelectedFridayTime2, setHasSelectedFridayTime2] =
        useState<boolean>(false);
    const [otherSuggestedTime, setOtherSuggestedTime] = useState<string>('');
    const [other, setOther] = useState<string>('');
    const [isSubmittingIntake, setIsSubmittingIntake] =
        useState<boolean>(false);

    const hasIntakeTimeslots = INTAKE_TIMESLOT_SPONSORS.includes(
        userProfile?.temp_group_sponsor_id ?? ''
    );

    const onLinkedInInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLinkedIn(target.value);
        },
        []
    );

    const onCountryInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCountry(target.value);
        },
        []
    );

    const onStateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setState(target.value);
        },
        []
    );

    const onCityInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCity(target.value);
        },
        []
    );

    const onTitleInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setTitle(target.value);
        },
        []
    );

    const onChangeYearsExperience = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setYearsExperience(target.value as YearsExperienceOptions);
        },
        []
    );

    const onImaginedFutureInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setImaginedFuture(target.value);
        },
        []
    );

    const onSelectWednesdayTime = useCallback(
        (index: number) => () => {
            if (index === 0) {
                setHasSelectedWednesdayTime1(!hasSelectedWednesdayTime1);
            } else {
                setHasSelectedWednesdayTime2(!hasSelectedWednesdayTime2);
            }
        },
        [hasSelectedWednesdayTime1, hasSelectedWednesdayTime2]
    );

    const onSelectFridayTime = useCallback(
        (index: number) => () => {
            if (index === 0) {
                setHasSelectedFridayTime1(!hasSelectedFridayTime1);
            } else {
                setHasSelectedFridayTime2(!hasSelectedFridayTime2);
            }
        },
        [hasSelectedFridayTime1, hasSelectedFridayTime2]
    );

    const onOtherSuggestedTimeInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setOtherSuggestedTime(target.value);
        },
        []
    );

    const onOtherInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOther(target.value);
        },
        []
    );

    const internalOnSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingIntake) {
                return;
            }
            setIsSubmittingIntake(true);

            if (
                hasIntakeTimeslots &&
                !hasSelectedWednesdayTime1 &&
                !hasSelectedWednesdayTime2 &&
                !hasSelectedFridayTime1 &&
                !hasSelectedFridayTime1 &&
                otherSuggestedTime === ''
            ) {
                showErrorToast(
                    'Please select a time slot for your ongoing sessions'
                );
                setIsSubmittingIntake(false);
                return;
            }

            const { error: profileError } = await supabase
                .from('profiles')
                .update({
                    linkedin: linkedIn,
                    imagined_future: imaginedFuture,
                    raw_data: {
                        ...(parsedRawData ?? {}),
                        headline: title,
                        country,
                        state,
                        city,
                    },
                })
                .eq('id', userId);

            if (profileError != null) {
                showErrorToast(profileError.message);
            }

            const availableTimeSlots = [];

            if (hasSelectedWednesdayTime1) {
                availableTimeSlots.push(
                    `Wednesday, ${INTAKE_TIMESLOT_OPTIONS[0]}`
                );
            }
            if (hasSelectedWednesdayTime2) {
                availableTimeSlots.push(
                    `Wednesday, ${INTAKE_TIMESLOT_OPTIONS[1]}`
                );
            }
            if (hasSelectedFridayTime1) {
                availableTimeSlots.push(
                    `Friday, ${INTAKE_TIMESLOT_OPTIONS[0]}`
                );
            }
            if (hasSelectedFridayTime2) {
                availableTimeSlots.push(
                    `Friday, ${INTAKE_TIMESLOT_OPTIONS[1]}`
                );
            }
            if (otherSuggestedTime !== '')
                availableTimeSlots.push(otherSuggestedTime);

            const { error: intakeError } = await supabase
                .from('intakes')
                .insert({
                    created_by: userId,
                    years_experience: yearsExperience,
                    imagined_future: imaginedFuture,
                    available_time_slots: hasIntakeTimeslots
                        ? availableTimeSlots
                        : null,
                    email: userEmail,
                    other,
                });

            if (intakeError == null) {
                showSuccessToast('Successfully submitted intake responses!');
                await onSubmit();
            } else {
                showErrorToast(intakeError.message);
            }

            setIsSubmittingIntake(false);
        },
        [
            isSubmittingIntake,
            hasIntakeTimeslots,
            hasSelectedWednesdayTime1,
            hasSelectedWednesdayTime2,
            hasSelectedFridayTime1,
            otherSuggestedTime,
            supabase,
            linkedIn,
            imaginedFuture,
            parsedRawData,
            title,
            country,
            state,
            city,
            userId,
            hasSelectedFridayTime2,
            yearsExperience,
            userEmail,
            other,
            showErrorToast,
            showSuccessToast,
            onSubmit,
        ]
    );

    return (
        <>
            <AuthenticateRoute />

            <Forms.FullPageOuterWrapper>
                <Forms.Heading>{`Welcome to The Grand World!`}</Forms.Heading>
                <Spacing.Bumper />
                <Fonts.BodyText>
                    {`We're delighted to curate your Grand Council group! This experience will strengthen your clarity and confidence as a leader, all with the support of a great community.`}
                </Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.BodyTextBold>{`Expectations:`}</Fonts.BodyTextBold>
                <Fonts.BodyText>
                    {`Sessions take place on Zoom. The meeting time and cadence of the session is determined by company and group availability. Time is precious and we believe consistent attendance will maximize the program’s value. These interactive sessions build on each other, so please do your best to attend all sessions.`}
                </Fonts.BodyText>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={internalOnSubmit}>
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>{`LinkedIn`}</Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        value={linkedIn}
                        onInput={onLinkedInInput}
                        isError={false}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Country`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="country"
                        name="country"
                        placeholder="eg. México"
                        value={country}
                        onInput={onCountryInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`State`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="state"
                        name="state"
                        placeholder="eg. Jalisco"
                        value={state}
                        onInput={onStateInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`City`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="city"
                        name="city"
                        placeholder="eg. Guadalajara"
                        value={city}
                        onInput={onCityInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Title`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        onInput={onTitleInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`How many years of professional experience do you have? (required)`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeYearsExperience}
                        value={yearsExperience ?? ''}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {YEARS_EXPERIENCE_OPTIONS.map(
                            (yearsExperinceOption) => (
                                <option
                                    key={yearsExperinceOption}
                                    value={yearsExperinceOption}
                                >
                                    {yearsExperinceOption}
                                </option>
                            )
                        )}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Imagine it is 6 months from now. Where do you hope to be?`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <Inputs.TextArea
                        id="imagined future"
                        name="imagined future"
                        value={imaginedFuture}
                        onInput={onImaginedFutureInput}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />

                    {hasIntakeTimeslots ? (
                        <>
                            <Fonts.InputLabel>
                                {`Please select all of the time slots that work for you. The program will meet every other week September - December.`}
                            </Fonts.InputLabel>
                            <Spacing.Bumper />
                            <TimeSlotWrapper>
                                <Forms.ExplainerTextWrapper>
                                    <Fonts.BodyQuote>{`Wednesdays`}</Fonts.BodyQuote>
                                </Forms.ExplainerTextWrapper>
                                {INTAKE_TIMESLOT_OPTIONS.map(
                                    (timeslotOption, index) => (
                                        <Inputs.CheckRadioInputWrapper
                                            key={index}
                                        >
                                            <Inputs.Checkbox
                                                onChange={onSelectWednesdayTime(
                                                    index
                                                )}
                                                value={timeslotOption}
                                                type="checkbox"
                                                checked={
                                                    index === 0
                                                        ? hasSelectedWednesdayTime1
                                                        : hasSelectedWednesdayTime2
                                                }
                                            />
                                            <Fonts.BodyText>
                                                {timeslotOption}
                                            </Fonts.BodyText>
                                        </Inputs.CheckRadioInputWrapper>
                                    )
                                )}
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Forms.ExplainerTextWrapper>
                                    <Fonts.BodyQuote>{`Fridays`}</Fonts.BodyQuote>
                                </Forms.ExplainerTextWrapper>
                                {INTAKE_TIMESLOT_OPTIONS.map(
                                    (timeslotOption, index) => (
                                        <Inputs.CheckRadioInputWrapper
                                            key={index}
                                        >
                                            <Inputs.Checkbox
                                                onChange={onSelectFridayTime(
                                                    index
                                                )}
                                                value={timeslotOption}
                                                type="checkbox"
                                                checked={
                                                    index === 0
                                                        ? hasSelectedFridayTime1
                                                        : hasSelectedFridayTime2
                                                }
                                            />
                                            <Fonts.BodyText>
                                                {timeslotOption}
                                            </Fonts.BodyText>
                                        </Inputs.CheckRadioInputWrapper>
                                    )
                                )}
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Forms.ExplainerTextWrapper>
                                    <Fonts.BodyQuote>
                                        {`Is there another day and time slot available?`}
                                    </Fonts.BodyQuote>
                                </Forms.ExplainerTextWrapper>
                                <TextInput
                                    type="text"
                                    id="otherSuggestedTime"
                                    name="otherSuggestedTime"
                                    value={otherSuggestedTime}
                                    onInput={onOtherSuggestedTimeInput}
                                    isError={false}
                                />
                            </TimeSlotWrapper>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}

                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Is there anything else you'd like for your coach to consider before your group starts?`}
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <Inputs.TextArea
                        id="otherFeedback"
                        name="otherFeedback"
                        value={other}
                        onInput={onOtherInput}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <LoadingButton
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingIntake}
                    >
                        {`Submit`}
                    </LoadingButton>
                </Forms.Wrapper>
            </Forms.FullPageOuterWrapper>
        </>
    );
}

export default SubmitIntake;
