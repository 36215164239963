import styled, { css } from 'styled-components';

import { colors, Fonts } from 'styles/theme';

interface DropdownProps {
    $isDropdownOpen: boolean;
}

export const Dropdown = styled.div<DropdownProps>`
    position: relative;
    background-color: ${colors.white};
    border-radius: 4px;
    width: 100%;
    height: 40px;
    cursor: pointer;

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    /* box-shadow: ${colors.boxShadow}; */
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}
`;

export const DropdownOption = styled.div<DropdownProps>`
    position: absolute;
    z-index: 10;
    background-color: ${colors.white};
    border-radius: 4px;
    width: 100%;
    height: 40px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.primary2};

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    /* box-shadow: ${colors.boxShadow}; */
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}
`;

export const AlternateDropdownOption = styled(DropdownOption)<DropdownProps>`
    z-index: 1;
    top: 0;
    left: 0;
    background-color: ${colors.white};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${colors.primary1};
    pointer-events: none;
    transition: 0.2s;

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    top: 40px;
                    box-shadow: ${colors.boxShadow};
                    pointer-events: all;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;

export const DropdownCaret = styled.img<DropdownProps>`
    transition: 0.2s;

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    transform: scaleY(-1);
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;

interface SessionOverviewWrapperProps {
    $removeMargin: boolean;
}

export const SessionOverviewWrapper = styled.div<SessionOverviewWrapperProps>`
    margin-top: 24px;
    text-align: left;
    overflow: hidden;

    ${(props) => {
        if (props.$removeMargin === true) {
            return css`
                margin: 0;
            `;
        }
    }}
`;

export const BulletList = styled.ol`
    list-style: outside;
    margin-top: 26px;
    margin-left: 32px;
`;

export const BulletListItem = styled.li`
    padding-left: 4px;
`;

export const BulletText = styled(Fonts.BodyText).attrs({ as: 'span' })``;

export const YourLearningBoldText = styled(Fonts.BodyText).attrs({
    as: 'span',
})`
    font-family: 'OpenSans-SemiBold';
`;
