import { ProfileData } from 'hooks/useSupabase';

import React, { useCallback, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { PROFILE_QUESTIONS } from 'libs/constants';

import RequiredAsterisk from 'components/RequiredAsterisk';
import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';
import LoadingButton from 'components/LoadingButton';

import X from 'assets/x-black.svg';

import { Fonts, Inputs, Overlays, Forms, Spacing } from 'styles/theme';
import { BottomButtonsWrapper, CloseButtonWrapper } from 'styles/imageSelector';

export interface EditProfileProps {
    profile: ProfileData;
    onClose: () => void;
}

function EditProfile(props: EditProfileProps) {
    const { profile, onClose } = props;

    const { supabase, userId, getProfiles } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showSuccessToast, showErrorToast } = useToast();

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(profile.raw_data ?? {})),
        [profile.raw_data]
    );

    const [firstName, setFirstName] = useState<string>(
        profile.full_name?.split(' ')[0] ?? 'Grand Explorer'
    );
    const [lastName, setLastName] = useState<string>(
        profile.full_name?.split(' ')[1] ?? ''
    );
    const [headline, setHeadline] = useState<string>(
        parsedRawData?.headline ?? ''
    );
    const [country, setCountry] = useState<string>(
        parsedRawData?.country ?? ''
    );
    const [state, setState] = useState<string>(parsedRawData?.state ?? '');
    const [city, setCity] = useState<string>(parsedRawData?.city ?? '');
    const [firstQuestionIndex, setFirstQuestionIndex] = useState<number | null>(
        parsedRawData?.first_question_index ?? null
    );
    const [firstQuestionResponse, setFirstQuestionResponse] = useState<string>(
        parsedRawData?.first_question_response ?? ''
    );
    const [secondQuestionIndex, setSecondQuestionIndex] = useState<
        number | null
    >(parsedRawData?.second_question_index ?? null);
    const [secondQuestionResponse, setSecondQuestionResponse] =
        useState<string>(parsedRawData?.second_question_response ?? '');
    const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);

    const onFirstNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setFirstName(target.value);
        },
        []
    );

    const onLastNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLastName(target.value);
        },
        []
    );

    const onHeadlineInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setHeadline(target.value);
        },
        []
    );

    const onCountryInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCountry(target.value);
        },
        []
    );

    const onStateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setState(target.value);
        },
        []
    );

    const onCityInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCity(target.value);
        },
        []
    );

    const onChangeFirstQuestionIndex = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setFirstQuestionIndex(parseInt(target.value));
        },
        []
    );

    const onChangeFirstQuestionResponse = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setFirstQuestionResponse(target.value);
        },
        []
    );

    const onChangeSecondQuestionIndex = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSecondQuestionIndex(parseInt(target.value));
        },
        []
    );

    const onChangeSecondQuestionResponse = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setSecondQuestionResponse(target.value);
        },
        []
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isUpdatingProfile) {
                return;
            }
            setIsUpdatingProfile(true);

            const { error } = await supabase
                .from('profiles')
                .update({
                    full_name: `${firstName} ${lastName}`,
                    raw_data: {
                        ...(parsedRawData ?? {}),
                        headline,
                        country,
                        state,
                        city,
                        first_question_index: firstQuestionIndex,
                        first_question_response: firstQuestionResponse,
                        second_question_index: secondQuestionIndex,
                        second_question_response: secondQuestionResponse,
                    },
                })
                .eq('id', userId);

            if (error == null) {
                getProfiles?.();
                onClose();
                showSuccessToast(`You've successfully edited your profile!`);

                sendEvent?.('profile_info_save');
            } else {
                showErrorToast(error.message);
            }

            setIsUpdatingProfile(false);
        },
        [
            city,
            country,
            firstName,
            firstQuestionIndex,
            firstQuestionResponse,
            getProfiles,
            headline,
            isUpdatingProfile,
            lastName,
            onClose,
            parsedRawData,
            secondQuestionIndex,
            secondQuestionResponse,
            sendEvent,
            showErrorToast,
            showSuccessToast,
            state,
            supabase,
            userId,
        ]
    );

    return (
        <>
            <Forms.CloseButtonWrapper onClick={onClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Overlays.ModalInnerScroller>
                <Forms.HeadingAlt>{`Edit My Profile`}</Forms.HeadingAlt>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={onSubmit}>
                    <Fonts.SmallCapsText>{`Name`}</Fonts.SmallCapsText>
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`First Name`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="first-name"
                        name="first-name"
                        value={firstName}
                        onInput={onFirstNameInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`Last Name`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="last-name"
                        name="last-name"
                        value={lastName}
                        onInput={onLastNameInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Fonts.SmallCapsText>{`Headline`}</Fonts.SmallCapsText>
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`How do you describe yourself?`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="headline"
                        name="headling"
                        placeholder="eg. HR Manager at The Grand or Marketing Wiz Extraordinaire"
                        value={headline}
                        onInput={onHeadlineInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Fonts.SmallCapsText>{`Location`}</Fonts.SmallCapsText>
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`Country`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="country"
                        name="country"
                        placeholder="eg. México"
                        value={country}
                        onInput={onCountryInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`State`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="state"
                        name="state"
                        placeholder="eg. Jalisco"
                        value={state}
                        onInput={onStateInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`City`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <TextInput
                        type="text"
                        id="city"
                        name="city"
                        placeholder="eg. Guadalajara"
                        value={city}
                        onInput={onCityInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <SelectInput
                        onInput={onChangeFirstQuestionIndex}
                        value={firstQuestionIndex ?? ''}
                        required
                    >
                        <option value="" label="Question 1" hidden />
                        {PROFILE_QUESTIONS.map((questionObject, index) => (
                            <option
                                key={index}
                                value={index}
                                disabled={index === secondQuestionIndex}
                            >
                                {questionObject.question}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="first-question-response"
                        name="first-question-response"
                        placeholder={
                            firstQuestionIndex == null
                                ? ''
                                : PROFILE_QUESTIONS[firstQuestionIndex]
                                      ?.placeholderText
                        }
                        value={firstQuestionResponse}
                        onInput={onChangeFirstQuestionResponse}
                        disabled={firstQuestionIndex == null}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <SelectInput
                        onInput={onChangeSecondQuestionIndex}
                        value={secondQuestionIndex ?? ''}
                        required
                    >
                        <option value="" label="Question 2" hidden />
                        {PROFILE_QUESTIONS.map((questionObject, index) => (
                            <option
                                key={index}
                                value={index}
                                disabled={index === firstQuestionIndex}
                            >
                                {questionObject.question}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="second-question-response"
                        name="second-question-response"
                        placeholder={
                            secondQuestionIndex == null
                                ? ''
                                : PROFILE_QUESTIONS[secondQuestionIndex]
                                      ?.placeholderText
                        }
                        value={secondQuestionResponse}
                        onInput={onChangeSecondQuestionResponse}
                        disabled={secondQuestionIndex == null}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <BottomButtonsWrapper>
                        <LoadingButton
                            type="submit"
                            value="Submit"
                            isLoading={isUpdatingProfile}
                        >
                            {`Save`}
                        </LoadingButton>
                        <CloseButtonWrapper onClick={onClose}>
                            <Fonts.BodyTextLink>{`Close`}</Fonts.BodyTextLink>
                        </CloseButtonWrapper>
                    </BottomButtonsWrapper>
                </Forms.Wrapper>
            </Overlays.ModalInnerScroller>
        </>
    );
}

export default EditProfile;
