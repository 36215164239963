import { ProfileData } from 'hooks/useSupabase';
import { PeriodicSurveyData } from 'components/PeriodicSurvey';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAnalytics } from 'hooks/useAnalytics';
import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { KUDOS_GROUPS, EXIT_RESPONSES, KUDOS_TYPES } from 'libs/constants';

import RequiredAsterisk from 'components/RequiredAsterisk';
import PeriodicSurvey from 'components/PeriodicSurvey';
import LoadingButton from 'components/LoadingButton';
import SelectInput from 'components/SelectInput';

import X from 'assets/x-black.svg';

import { Fonts, Inputs, Overlays, Forms, Spacing } from 'styles/theme';
import { KudosText } from 'styles/submitClosing';

type ClosingExit = (typeof EXIT_RESPONSES)[number];

interface SubmitClosingProps {
    sessionNumber: number;
    sessionDate: string;
    isPreviousSessionClosing: boolean;
    isOngoing: boolean;
    onClose: () => void;
    isVisible: boolean;
}

function SubmitClosing(props: SubmitClosingProps) {
    const {
        sessionNumber,
        sessionDate,
        isPreviousSessionClosing,
        isOngoing,
        onClose,
        isVisible,
    } = props;

    const { sendEvent } = useAnalytics();
    const { supabase, userId, userProfile } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();

    const [periodicSurvey, setPeriodicSurvey] = useState<PeriodicSurveyData>(
        {}
    );
    const [learnings, setLearnings] = useState<string>('');
    const [selectedExit, setSelectedExit] = useState<ClosingExit | null>(null);
    const [otherFeedback, setOtherFeedback] = useState<string>('');
    const [otherGroupMembers, setOtherGroupMembers] = useState<
        Array<ProfileData>
    >([]);
    const [kudosToMembers, setKudosToMembers] = useState<object>({
        ...KUDOS_TYPES,
    });
    const [isSubmittingClosing, setIsSubmittingClosing] =
        useState<boolean>(false);

    const sessionDateObject = useMemo(
        () => new Date(sessionDate),
        [sessionDate]
    );

    const isInKudosGroup = KUDOS_GROUPS.includes(
        userProfile?.member_groups?.[0] ?? ''
    );

    useEffect(() => {
        const getOtherGroupMembers = async () => {
            if (isInKudosGroup) {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('is_active', true)
                    .neq('id', userId)
                    .contains(
                        'member_groups',
                        userProfile?.member_groups ?? []
                    );

                if (error == null) {
                    setOtherGroupMembers(data);
                } else {
                    showErrorToast(error.message);
                }
            }
        };

        if (isInKudosGroup) {
            getOtherGroupMembers();
        }
    }, [
        isInKudosGroup,
        showErrorToast,
        supabase,
        userId,
        userProfile?.member_groups,
    ]);
    const onLearningsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setLearnings(target.value);
        },
        []
    );

    const onSelectMemberForKudos = useCallback(
        (kudosType: string) => (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            const newKudosToMembers = { ...kudosToMembers };
            // This always happens with constant objects that you key into,
            // still need to figure that typing out one day...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newKudosToMembers[kudosType] = target.value;
            setKudosToMembers(newKudosToMembers);
        },
        [kudosToMembers]
    );

    const onChangeExit = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelectedExit(target.value as ClosingExit);
        },
        []
    );

    const onOtherFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOtherFeedback(target.value);
        },
        []
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (userProfile?.is_test) {
                showErrorToast('Test accounts cannot submit closings');
                return;
            }

            if (isSubmittingClosing) {
                return;
            }
            setIsSubmittingClosing(true);

            const { error } = await supabase.from('closings').insert({
                created_by: userId,
                group_id: userProfile?.member_groups?.[0],
                session_number: sessionNumber,
                session_date: sessionDate,
                raw_data: {
                    learnings,
                    exit: selectedExit,
                    other: otherFeedback,
                    member_name: userProfile?.full_name,
                    // Pass everything we save in DB columns to raw_data as well
                    created_by: userId,
                    group_id: userProfile?.member_groups?.[0],
                    session_number: sessionNumber,
                    session_date: sessionDate,
                    kudos: kudosToMembers,
                    periodic_survey: periodicSurvey,
                },
            });

            if (error == null) {
                onClose();
                showSuccessToast('Your session closing has been submitted!');

                sendEvent?.('session_closing_submitted', {
                    session_number: sessionNumber,
                    session_date: sessionDate,
                    group_id: userProfile?.member_groups?.[0],
                });
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingClosing(false);
        },
        [
            userProfile?.is_test,
            userProfile?.member_groups,
            userProfile?.full_name,
            isSubmittingClosing,
            supabase,
            userId,
            sessionNumber,
            sessionDate,
            learnings,
            selectedExit,
            otherFeedback,
            kudosToMembers,
            periodicSurvey,
            showErrorToast,
            onClose,
            showSuccessToast,
            sendEvent,
        ]
    );

    return (
        <>
            <Forms.CloseButtonWrapper onClick={onClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Overlays.ModalInnerScroller>
                <Fonts.LargeText>
                    {isOngoing
                        ? `${sessionDateObject.toLocaleString('en-us', {
                              month: 'long',
                          })} ${sessionDateObject.getDate()}, ${sessionDateObject.getFullYear()}`
                        : `Session ${sessionNumber}`}
                </Fonts.LargeText>
                <Forms.Heading>{`Session Closing Space`}</Forms.Heading>
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={onSubmit}>
                    {sessionNumber !== 0 && sessionNumber % 4 === 0 ? (
                        <>
                            <PeriodicSurvey
                                periodicSurvey={periodicSurvey}
                                setPeriodicSurvey={setPeriodicSurvey}
                                isVisible={isVisible}
                            />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Fonts.Heading3 $color="orange">
                                {`Session Closing`}
                            </Fonts.Heading3>
                            <Spacing.Bumper />
                            <Fonts.LargeText>
                                {`At the end of every session, we will ask you to self reflect on your session experience.`}
                            </Fonts.LargeText>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}
                    <Fonts.InputLabel>
                        {`What’s your learning from ${
                            isPreviousSessionClosing ? `your last` : `today’s`
                        } session? (${
                            isInKudosGroup
                                ? `Your learnings will be shared with your coach. They'll also be de-identified and then shared with your group and your sponsor.`
                                : 'This will be shared with your coach and group'
                        })`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Fonts.SmallText>
                        {`Scientific research has shown when you skip documenting what you’ve learned, you give up on 70% of your potential learning. This will be shared back with your coaches, your group, and you in the future.`}
                    </Fonts.SmallText>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="learnings"
                        name="learnings"
                        required
                        value={learnings}
                        onInput={onLearningsInput}
                    />
                    {isInKudosGroup ? (
                        <>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Divider />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Fonts.InputLabel>
                                {`Give your group members some kudos 👏 (optional)`}
                            </Fonts.InputLabel>
                            <Spacing.Bumper />
                            <Fonts.SmallText>
                                {`Giving and receiving gratitude helps you develop emotional intelligence, and improves your wellbeing.`}
                            </Fonts.SmallText>
                            {Object.keys(KUDOS_TYPES).map((kudosType) => (
                                <>
                                    <Spacing.Bumper />
                                    <KudosText>{kudosType}</KudosText>
                                    <SelectInput
                                        onInput={onSelectMemberForKudos(
                                            kudosType
                                        )}
                                        // See above
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        value={kudosToMembers[kudosType]}
                                        $size="small"
                                        $fontFamily="alt"
                                    >
                                        <option
                                            value=""
                                            label="Choose a member"
                                        />
                                        {otherGroupMembers.map(
                                            (memberProfileData, index) => (
                                                <option
                                                    key={index}
                                                    value={memberProfileData.id}
                                                >
                                                    {
                                                        memberProfileData.full_name
                                                    }
                                                </option>
                                            )
                                        )}
                                    </SelectInput>
                                </>
                            ))}
                        </>
                    ) : null}
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.LargeText>
                        {`The next two answers will help The Grand product team improve your experience.`}
                    </Fonts.LargeText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`What is your reaction to the following statement? "I feel that this session was a great use of my time."`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <SelectInput
                        onInput={onChangeExit}
                        value={selectedExit ?? ''}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {EXIT_RESPONSES.map((closingExit) => (
                            <option key={closingExit} value={closingExit}>
                                {closingExit}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`Is there anything else you'd like to share?`}
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="otherFeedback"
                        name="otherFeedback"
                        value={otherFeedback}
                        onInput={onOtherFeedbackInput}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <LoadingButton
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingClosing}
                    >
                        {`Submit`}
                    </LoadingButton>
                </Forms.Wrapper>
            </Overlays.ModalInnerScroller>
        </>
    );
}

export default SubmitClosing;
