import { PropsWithChildren } from 'react';

import React from 'react';

import CloudsBackgroundImage from 'assets/background-sparse-clouds.png';
import GreenMountainsBackgroundImage from 'assets/background-green-mountains.png';

import {
    MainWrapper,
    CloudsBackground,
    GreenMountainsLandscape,
    ContentOuterWrapper,
} from 'styles/backgroundWrapper';
import { Overlays, Spacing } from 'styles/theme';
import Footer from './Footer';

interface BackgroundWrapperProps extends PropsWithChildren {
    shouldIncludeFooter?: boolean;
    shouldIncludeBumper?: boolean;
}

function BackgroundWrapper(props: BackgroundWrapperProps) {
    const { children, shouldIncludeFooter, shouldIncludeBumper } = props;

    return (
        <MainWrapper>
            <ContentOuterWrapper
                $shouldIncludeFooter={shouldIncludeFooter ?? false}
            >
                <Overlays.TextureOverlay />
                <CloudsBackground src={CloudsBackgroundImage} />
                <GreenMountainsLandscape src={GreenMountainsBackgroundImage} />
                {children}
            </ContentOuterWrapper>

            {shouldIncludeFooter ?? false ? <Footer /> : null}

            {shouldIncludeBumper ?? false ? (
                <>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </>
            ) : null}
        </MainWrapper>
    );
}

export default BackgroundWrapper;
