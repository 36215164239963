import { IncompleteRecapData, GroupMember } from 'components/Coach';
import { GroupData } from 'components/Sessions';
import { FormStep } from 'components/SteppedForm';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';
import { usePrevious } from 'hooks/usePrevious';
import { useAnalytics } from 'hooks/useAnalytics';

import { deepEqual } from 'libs/utils';
import {
    EXIT_RESPONSES,
    CLARITY_KNOWLEDGE_OBJECTIVE,
    SESSION_EXERCISES,
    ADMIN_TIMES,
    ADMIN_TYPES,
    ADMIN_TIME_THRESHOLDS,
    CLARITY_SESSION_TITLE,
} from 'libs/constants';

import SteppedForm from 'components/SteppedForm';
import RequiredAsterisk from 'components/RequiredAsterisk';
import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';

import { Buttons, Fonts, Inputs, Forms, Spacing } from 'styles/theme';
import { SessionExercisesWrapper } from 'styles/coach';
import MultiSelectDropdown from './MultiSectionDropdown';
import { ObjectiveSelectorWrapper } from 'styles/submitRecap';

type SessionExercise = (typeof SESSION_EXERCISES)[number];
type RecapExit = (typeof EXIT_RESPONSES)[number];
type AdminTime = (typeof ADMIN_TIMES)[number];
type AdminType = (typeof ADMIN_TYPES)[number];
interface Objective {
    title: string;
    points: Array<string>;
}

interface SubmitRecapProps {
    selectedIncompleteRecap: IncompleteRecapData;
    groupMembers: {
        [name: string]: GroupMember;
    };
    onClose: () => void;
}

function SubmitRecap(props: SubmitRecapProps) {
    const { selectedIncompleteRecap, groupMembers, onClose } = props;

    const { supabase, userId, userProfile } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();
    const { sendEvent } = useAnalytics();

    const [internalGroupMembers, setInternalGroupMembers] = useState<{
        [name: string]: GroupMember;
    }>({});
    const [topics, setTopics] = useState<Array<string>>(['', '', '']);
    const [learnings, setLearnings] = useState<string>('');
    const [selectedExercises, setSelectedExercises] = useState<
        Array<SessionExercise>
    >([]);
    const [selectedExit, setSelectedExit] = useState<RecapExit | null>(null);
    const [adminTime, setAdminTime] = useState<AdminTime | null>(null);
    const [shouldShowAdminTypes, setShouldShowAdminTypes] =
        useState<boolean>(false);
    const [adminType, setAdminType] = useState<AdminType | null>(null);
    const [otherComments, setOtherComments] = useState<string>('');
    const [group, setGroup] = useState<GroupData | null>(null);
    const [modifiedGroupObjectives, setModifiedGroupObjectives] =
        useState<object>({});
    const [currentBankedObjectives, setCurrentBankedObjectives] = useState<
        Array<Objective>
    >([]);
    const [isUpdatingObjectives, setIsUpdatingObjectives] =
        useState<boolean>(false);
    const [isSubmittingRecap, setIsSubmittingRecap] = useState<boolean>(false);

    const prevGroupMembers = usePrevious(groupMembers);
    useEffect(() => {
        if (prevGroupMembers !== groupMembers) {
            setInternalGroupMembers(groupMembers);
        }
    }, [groupMembers, prevGroupMembers]);

    const getGroup = useCallback(async () => {
        const { data, error } = await supabase
            .from('groups')
            .select('*')
            .eq('gid', selectedIncompleteRecap.group_id);

        if (error == null) {
            setGroup(data[0]);
        } else {
            showErrorToast(error.message);
        }
    }, [selectedIncompleteRecap.group_id, showErrorToast, supabase]);

    useEffect(() => {
        getGroup();
    }, [getGroup]);

    const parsedGroupObjectives = useMemo(
        () => JSON.parse(JSON.stringify(group?.objectives ?? {})),
        [group?.objectives]
    );
    const currentGroupObjectivesArray = useMemo(() => {
        const objectivesToOrder =
            Object.keys(modifiedGroupObjectives).length > 0
                ? modifiedGroupObjectives
                : parsedGroupObjectives;

        const returnArray = [];
        for (let i = 1; i <= Object.keys(objectivesToOrder).length; i++) {
            returnArray.push({
                sessionNumber: i.toString(),
                objective: objectivesToOrder[i.toString()],
            });
        }

        return returnArray;
    }, [modifiedGroupObjectives, parsedGroupObjectives]);
    const parsedBankedObjectives = useMemo(
        () => JSON.parse(JSON.stringify(group?.banked_objectives ?? [])),
        [group?.banked_objectives]
    );
    useEffect(() => {
        setCurrentBankedObjectives(parsedBankedObjectives);
    }, [parsedBankedObjectives]);

    const recapSessionNumber =
        group?.session_counter == null
            ? 0
            : group?.session_counter - (group?.has_session_today ? 0 : 1);

    const onUpdateAttendee = useCallback(
        (memberName: string) => (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            const newGroupMembers = { ...internalGroupMembers };
            if (newGroupMembers[memberName] != null) {
                // Null check above — TypeScript linter approves, but transpiler does not
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newGroupMembers[memberName].attended = target.checked;
            }
            setInternalGroupMembers(newGroupMembers);
        },
        [internalGroupMembers]
    );

    const onUpdateObjectiveSequence = useCallback(
        (sessionNumber: number) => (selectedValue: string) => {
            const parsedGroupObjectivesClone = structuredClone(
                parsedGroupObjectives
            );
            const currentBankedObjectivesClone = structuredClone(
                currentBankedObjectives
            );
            const previouslySelectedObjective =
                // Same data structure that we're getting from the database, an untyped
                // object, which triggers a TypeScript index signature warning
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                modifiedGroupObjectives[sessionNumber.toString()];
            const originalObjective =
                parsedGroupObjectivesClone[sessionNumber.toString()];

            if (
                typeof selectedValue === 'string' &&
                isNaN(parseInt(selectedValue))
            ) {
                if (selectedValue === CLARITY_KNOWLEDGE_OBJECTIVE.title) {
                    parsedGroupObjectivesClone[sessionNumber.toString()] =
                        CLARITY_KNOWLEDGE_OBJECTIVE;

                    if (
                        originalObjective.title !==
                        CLARITY_KNOWLEDGE_OBJECTIVE.title
                    ) {
                        currentBankedObjectivesClone.push(originalObjective);
                        setCurrentBankedObjectives(
                            currentBankedObjectivesClone
                        );
                    } else {
                        setCurrentBankedObjectives(parsedBankedObjectives);
                    }
                } else {
                    const selectedBankedObjective =
                        currentBankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === selectedValue
                        );
                    const bankedObjectiveIndex =
                        currentBankedObjectivesClone.findIndex(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === selectedValue
                        );
                    currentBankedObjectivesClone.splice(
                        bankedObjectiveIndex,
                        1
                    );

                    if (
                        previouslySelectedObjective != null &&
                        parsedBankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title ===
                                previouslySelectedObjective?.title
                        ) != null
                    ) {
                        currentBankedObjectivesClone.push(
                            previouslySelectedObjective
                        );
                    } else if (
                        originalObjective.title !==
                            CLARITY_KNOWLEDGE_OBJECTIVE.title &&
                        originalObjective.title !== CLARITY_SESSION_TITLE &&
                        currentBankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === originalObjective.title
                        ) == null
                    ) {
                        currentBankedObjectivesClone.push(originalObjective);
                    }

                    parsedGroupObjectivesClone[sessionNumber.toString()] =
                        selectedBankedObjective;
                    setCurrentBankedObjectives(currentBankedObjectivesClone);
                }
            } else {
                if (
                    // Same data structure that we're getting from the database, an untyped
                    // object, which triggers a TypeScript index signature warning
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    modifiedGroupObjectives[selectedValue]?.title !==
                    originalObjective?.title
                ) {
                    const newSelectedObjective =
                        parsedGroupObjectivesClone[selectedValue];
                    parsedGroupObjectivesClone[sessionNumber.toString()] =
                        newSelectedObjective;
                    parsedGroupObjectivesClone[selectedValue] =
                        originalObjective;
                }

                setCurrentBankedObjectives(parsedBankedObjectives);
            }

            setModifiedGroupObjectives(parsedGroupObjectivesClone);
        },
        [
            currentBankedObjectives,
            modifiedGroupObjectives,
            parsedBankedObjectives,
            parsedGroupObjectives,
        ]
    );

    const onToggleExercise = useCallback(
        (exercise: SessionExercise) =>
            (event: React.FormEvent<HTMLButtonElement>) => {
                event.preventDefault();

                const newSelectedExercises = [...selectedExercises];
                const exerciseIndex = newSelectedExercises.findIndex(
                    (selectedExercise) => selectedExercise === exercise
                );
                if (exerciseIndex === -1) {
                    newSelectedExercises.push(exercise);
                } else {
                    newSelectedExercises.splice(exerciseIndex, 1);
                }
                setSelectedExercises(newSelectedExercises);
            },
        [selectedExercises]
    );

    const onChangeTopic = useCallback(
        (topicIndex: number) => (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            const newTopics = [...topics];
            newTopics[topicIndex] = target.value;
            setTopics(newTopics);
        },
        [topics]
    );

    const onUpdateClosingWords = useCallback(
        (memberName: string) => (event: React.FormEvent<HTMLInputElement>) => {
            const newGroupMembers = { ...internalGroupMembers };
            const target = event.target as HTMLInputElement;
            if (newGroupMembers[memberName] != null) {
                // Null check above — TypeScript linter approves, but transpiler does not
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newGroupMembers[memberName].closingWords = target.value;
            }
        },
        [internalGroupMembers]
    );

    const onLearningsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setLearnings(target.value);
        },
        []
    );

    const onChangeExit = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelectedExit(target.value as RecapExit);
        },
        []
    );

    const onChangeAdminTime = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setAdminTime(target.value as AdminTime);
        },
        []
    );

    useEffect(() => {
        if (adminTime != null && ADMIN_TIME_THRESHOLDS.includes(adminTime)) {
            setShouldShowAdminTypes(true);
        } else {
            setShouldShowAdminTypes(false);
            setAdminType(null);
        }
    }, [adminTime]);

    const onChangeAdminType = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setAdminType(target.value as AdminType);
        },
        []
    );

    const onOtherCommentsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOtherComments(target.value);
        },
        []
    );

    const onSubmitObjectives = useCallback(
        (isRecapSession: boolean) => async () => {
            if (
                Object.keys(modifiedGroupObjectives).length > 0 &&
                !deepEqual(parsedGroupObjectives, modifiedGroupObjectives)
            ) {
                if (isUpdatingObjectives) {
                    return;
                }
                setIsUpdatingObjectives(true);

                const { error } = await supabase
                    .from('groups')
                    .update({
                        objectives: modifiedGroupObjectives,
                        banked_objectives: currentBankedObjectives,
                    })
                    .eq('gid', selectedIncompleteRecap.group_id);

                if (error == null) {
                    showSuccessToast(
                        'Your group objectives have been updated!'
                    );
                    setModifiedGroupObjectives({});
                    setCurrentBankedObjectives([]);
                    await getGroup();
                } else {
                    showErrorToast(error.message);
                }

                if (isRecapSession) {
                    sendEvent?.('recap_session_objective_updated');
                } else {
                    sendEvent?.('recap_next_objective_updated');
                }

                setIsUpdatingObjectives(false);
            }
        },
        [
            currentBankedObjectives,
            getGroup,
            isUpdatingObjectives,
            modifiedGroupObjectives,
            parsedGroupObjectives,
            selectedIncompleteRecap.group_id,
            sendEvent,
            showErrorToast,
            showSuccessToast,
            supabase,
        ]
    );

    const onSubmitRecap = useCallback(async () => {
        if (isSubmittingRecap) {
            return;
        }
        setIsSubmittingRecap(true);

        const { error } = await supabase.from('recaps').insert({
            created_by: userId,
            group_id: selectedIncompleteRecap?.group_id ?? '',
            session_date: selectedIncompleteRecap?.session_end ?? '',
            is_hq: userProfile?.is_hq,
            raw_data: {
                group_id: selectedIncompleteRecap?.group_id ?? '',
                coach_id: userId,
                coach_name: userProfile?.full_name,
                is_hq: userProfile?.is_hq,
                event_id: selectedIncompleteRecap?.event_id ?? '',
                session: {
                    aid: selectedIncompleteRecap?.aid,
                    session_date: selectedIncompleteRecap?.session_end ?? '',
                },
                expected_attendees: Object.keys(internalGroupMembers),
                present_attendees: Object.keys(internalGroupMembers).filter(
                    (memberName) =>
                        internalGroupMembers[memberName]?.attended === true
                ),
                closing_words: Object.keys(internalGroupMembers).map(
                    (memberName) =>
                        internalGroupMembers[memberName]?.closingWords
                ),
                attributed_closing_words: Object.keys(internalGroupMembers).map(
                    (memberName) =>
                        `${memberName}~~${internalGroupMembers[memberName]?.closingWords}`
                ),
                topic1: topics[0],
                topic2: topics[1],
                topic3: topics[2],
                learnings,
                selected_exercises: selectedExercises,
                exit: selectedExit,
                other: otherComments,
                admin_time: adminTime,
                admin_type: adminType,
            },
        });

        if (error == null) {
            showSuccessToast('Your session recap has been submitted!');
        } else {
            showErrorToast(error.message);
        }

        setIsSubmittingRecap(false);
    }, [
        isSubmittingRecap,
        supabase,
        userId,
        selectedIncompleteRecap?.group_id,
        selectedIncompleteRecap?.session_end,
        selectedIncompleteRecap?.event_id,
        selectedIncompleteRecap?.aid,
        userProfile?.is_hq,
        userProfile?.full_name,
        internalGroupMembers,
        topics,
        learnings,
        selectedExercises,
        selectedExit,
        otherComments,
        adminTime,
        adminType,
        showSuccessToast,
        showErrorToast,
    ]);

    const renderObjectiveSelectInput = useCallback(
        (sessionNumber: number) => {
            const multiSelectDropdownSections = [
                {
                    headerText: `Your group's current curriculum:`,
                    options: currentGroupObjectivesArray.map(
                        (objectiveDatum, index) => ({
                            value: objectiveDatum.sessionNumber,
                            label: objectiveDatum.objective?.title,
                            isDisabled: index + 1 < sessionNumber,
                        })
                    ),
                },
                {
                    headerText:
                        'Additional Clarity Council or Knowledge Share, if needed:',
                    options: [
                        {
                            value: CLARITY_KNOWLEDGE_OBJECTIVE.title,
                            label: CLARITY_KNOWLEDGE_OBJECTIVE.title,
                        },
                    ],
                },
            ];

            if (currentBankedObjectives.length > 0) {
                multiSelectDropdownSections.push({
                    headerText: `Objectives you've moved out of your curriculum:`,
                    options: currentBankedObjectives.map((objectiveDatum) => ({
                        value: objectiveDatum.title,
                        label: objectiveDatum.title,
                    })),
                });
            }

            return (
                <MultiSelectDropdown
                    onSelectOption={onUpdateObjectiveSequence(sessionNumber)}
                    selectedOptionIndex={sessionNumber - 1}
                    sections={multiSelectDropdownSections}
                    onOpenDropdown={() =>
                        sendEvent?.('recap_objectives_opened')
                    }
                />
            );
        },
        [
            currentBankedObjectives,
            currentGroupObjectivesArray,
            onUpdateObjectiveSequence,
            sendEvent,
        ]
    );

    const formSteps: Array<FormStep> = useMemo(() => {
        const formStespReturnArray = [];
        formStespReturnArray.push({
            isComplete: true,
            formNode: (
                <>
                    <Fonts.Heading3>{`Attendance`}</Fonts.Heading3>
                    <Spacing.Bumper />
                    <Fonts.BodyText>
                        {`Please check the box next to the names who have attended. The attendance will not be shared with your group.`}
                    </Fonts.BodyText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    {Object.keys(internalGroupMembers).map((memberName) => (
                        <>
                            <Forms.CheckboxWrapper
                                key={
                                    internalGroupMembers[memberName]?.profile.id
                                }
                            >
                                <Inputs.Checkbox
                                    type="checkbox"
                                    name="share"
                                    onChange={onUpdateAttendee(memberName)}
                                    checked={
                                        internalGroupMembers[memberName]
                                            ?.attended
                                    }
                                />
                                <Fonts.SmallText>{memberName}</Fonts.SmallText>
                            </Forms.CheckboxWrapper>
                            <Spacing.Bumper />
                        </>
                    ))}
                    <Fonts.FinePrintText>{`Your responses below will generate an email recap for you to review and share with your group (we don't send emails on your behalf). To ensure member confidentiality, please do not include specific names. Instead you can say “one founder” or “one member.” The Grand will reference these anonymized responses in monthly reports to partners.`}</Fonts.FinePrintText>
                </>
            ),
        });

        if (Object.keys(currentGroupObjectivesArray).length > 0) {
            formStespReturnArray.push({
                isComplete: true,
                onSubmitStep: onSubmitObjectives(true),
                formNode: (
                    <>
                        <Fonts.Heading3>{`Session Objectives`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`The intended session objective was to address `}
                            <Fonts.BodyQuote as="span" $color="orange">
                                {
                                    parsedGroupObjectives[recapSessionNumber]
                                        ?.title
                                }
                            </Fonts.BodyQuote>
                            {`. If you've made any adjustments, please update using the options below.`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <ObjectiveSelectorWrapper>
                            {renderObjectiveSelectInput(recapSessionNumber)}
                            <Spacing.Bumper />
                            {currentGroupObjectivesArray[
                                recapSessionNumber - 1
                            ]?.objective.points.map(
                                (point: string, index: number) => (
                                    <>
                                        <Fonts.BodyText>{point}</Fonts.BodyText>
                                        {index <
                                        (currentGroupObjectivesArray[
                                            recapSessionNumber - 1
                                        ]?.objective.points.length ?? 0) -
                                            1 ? (
                                            <Spacing.Bumper />
                                        ) : null}
                                    </>
                                )
                            )}
                        </ObjectiveSelectorWrapper>
                    </>
                ),
            });
        }

        formStespReturnArray.push({
            isComplete: !topics.includes(''),
            formNode: (
                <>
                    <Fonts.Heading3>{`Topics`}</Fonts.Heading3>
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`Your responses below will automatically be shared with all coaches in the #recaps-and-support Slack channel and recap email template.`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`Top 3 Session Topics`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    {(() => {
                        const selectOptions = [];
                        for (let i = 0; i <= 2; i++) {
                            selectOptions.push(
                                <>
                                    <TextInput
                                        key={i}
                                        type="text"
                                        placeholder="Topic"
                                        value={topics[i]}
                                        onInput={onChangeTopic(i)}
                                        isError={false}
                                    />
                                    <Spacing.Bumper />
                                </>
                            );
                        }
                        return selectOptions;
                    })()}
                </>
            ),
        });
        formStespReturnArray.push({
            isComplete: true,
            formNode: (
                <>
                    <Fonts.Heading3>{`Exercises`}</Fonts.Heading3>
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`Please select all the exercises that were used during the session. If they are not on this list, please share what you tried, in the next section below.`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <SessionExercisesWrapper>
                        {SESSION_EXERCISES.map((exercise) => (
                            <Buttons.Pill
                                key={exercise}
                                onClick={onToggleExercise(exercise)}
                                $isSelected={selectedExercises.includes(
                                    exercise
                                )}
                            >
                                <Buttons.OverlayMedium />
                                <Fonts.FinePrintText
                                    $color={
                                        selectedExercises.includes(exercise)
                                            ? 'white'
                                            : 'primary'
                                    }
                                >
                                    {exercise}
                                </Fonts.FinePrintText>
                            </Buttons.Pill>
                        ))}
                    </SessionExercisesWrapper>
                </>
            ),
        });

        if (
            Object.values(internalGroupMembers).find(
                (groupMember) => groupMember.attended === true
            ) != null
        ) {
            formStespReturnArray.push({
                isComplete: true,
                formNode: (
                    <>
                        <Fonts.Heading3>{`Closing Words`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`Please include the closing words for each attended member.`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        {Object.keys(internalGroupMembers).map((memberName) =>
                            internalGroupMembers[memberName]?.attended ? (
                                <>
                                    <Spacing.Bumper />
                                    <Forms.ExplainerTextWrapper>
                                        <Fonts.BodyText>
                                            {memberName}
                                        </Fonts.BodyText>
                                    </Forms.ExplainerTextWrapper>
                                    <TextInput
                                        type="text"
                                        placeholder="(Closing word...)"
                                        onInput={onUpdateClosingWords(
                                            memberName
                                        )}
                                        isError={false}
                                    />
                                    <Spacing.Bumper />
                                </>
                            ) : null
                        )}
                    </>
                ),
            });
        }

        formStespReturnArray.push({
            isComplete: learnings !== '',
            formNode: (
                <>
                    <Fonts.Heading3>{`Your Learnings`}</Fonts.Heading3>
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`What are some learnings from this session that would be helpful to other coaches? Please include at least one thought, observation, or technique you tried. Do not input N/A unless no one showed up to your session.`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="learnings"
                        name="learnings"
                        value={learnings}
                        onInput={onLearningsInput}
                    />
                </>
            ),
        });
        formStespReturnArray.push({
            isComplete:
                selectedExit != null &&
                adminTime != null &&
                (!shouldShowAdminTypes || adminType != null),
            onSubmitStep: onSubmitRecap,
            submitText: 'Submit',
            formNode: (
                <>
                    <Fonts.Heading3>{`Your session and product feedback`}</Fonts.Heading3>
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`The next four answers will only be shared with The Grand product team to help improve your experience.`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.BodyQuote $color="orange">
                        {`"The Grand’s training and support helped me feel prepared for this session."`}
                        <RequiredAsterisk />
                    </Fonts.BodyQuote>
                    <Spacing.Bumper />
                    <SelectInput
                        onInput={onChangeExit}
                        value={selectedExit ?? ''}
                    >
                        <option value="" hidden>{`Select`}</option>
                        {EXIT_RESPONSES.map((closingExit) => (
                            <option key={closingExit} value={closingExit}>
                                {closingExit}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.BodyText>
                        {`Select the amount of admin time you spent on this session:`}
                        <RequiredAsterisk />
                    </Fonts.BodyText>
                    <Spacing.Bumper />
                    <SelectInput
                        onInput={onChangeAdminTime}
                        value={adminTime ?? ''}
                    >
                        <option value="" label="Select" hidden />
                        {ADMIN_TIMES.map((adminTimeOption) => (
                            <option
                                key={adminTimeOption}
                                value={adminTimeOption}
                            >
                                {adminTimeOption}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    {shouldShowAdminTypes ? (
                        <>
                            <Spacing.Bumper />
                            <Fonts.BodyText>
                                {`Where did you spend most of your admin time?`}
                                <RequiredAsterisk />
                            </Fonts.BodyText>
                            <Spacing.Bumper />
                            <SelectInput
                                onInput={onChangeAdminType}
                                value={adminType ?? ''}
                            >
                                <option value="" label="Select" hidden />
                                {ADMIN_TYPES.map((adminTypeOption) => (
                                    <option
                                        key={adminTypeOption}
                                        value={adminTypeOption}
                                    >
                                        {adminTypeOption}
                                    </option>
                                ))}
                            </SelectInput>
                            <Spacing.Bumper />
                        </>
                    ) : null}
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`Is there anything else you'd like to share?`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="other-comments"
                        name="other-comments"
                        value={otherComments}
                        onInput={onOtherCommentsInput}
                    />
                </>
            ),
        });

        if (
            recapSessionNumber < Object.keys(parsedGroupObjectives).length &&
            Object.keys(currentGroupObjectivesArray).length > 0
        ) {
            formStespReturnArray.push({
                isComplete: true,
                onSubmitStep: onSubmitObjectives(false),
                shouldHideProgress: true,
                formNode: (
                    <>
                        <Fonts.Heading1>{`Thanks for your recap!`}</Fonts.Heading1>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`For your next `}
                            <Fonts.BodyTextBold as="span">
                                {selectedIncompleteRecap.group_id}
                            </Fonts.BodyTextBold>
                            {` session, your objectives will cover `}
                            <Fonts.BodyQuote as="span" $color="orange">
                                {
                                    parsedGroupObjectives[
                                        recapSessionNumber + 1
                                    ]?.title
                                }
                            </Fonts.BodyQuote>
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`If you do not intend cover the objective, please update changes below.`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <ObjectiveSelectorWrapper>
                            {renderObjectiveSelectInput(recapSessionNumber + 1)}
                            <Spacing.Bumper />
                            {currentGroupObjectivesArray[
                                recapSessionNumber
                            ]?.objective.points.map(
                                (point: string, index: number) => (
                                    <>
                                        <Fonts.BodyText>{point}</Fonts.BodyText>
                                        {index <
                                        (currentGroupObjectivesArray[
                                            recapSessionNumber
                                        ]?.objective.points.length ?? 0) -
                                            1 ? (
                                            <Spacing.Bumper />
                                        ) : null}
                                    </>
                                )
                            )}
                        </ObjectiveSelectorWrapper>
                    </>
                ),
            });
        }

        return formStespReturnArray;
    }, [
        adminTime,
        adminType,
        currentGroupObjectivesArray,
        internalGroupMembers,
        learnings,
        onChangeAdminTime,
        onChangeAdminType,
        onChangeExit,
        onChangeTopic,
        onLearningsInput,
        onOtherCommentsInput,
        onSubmitObjectives,
        onSubmitRecap,
        onToggleExercise,
        onUpdateAttendee,
        onUpdateClosingWords,
        otherComments,
        parsedGroupObjectives,
        recapSessionNumber,
        renderObjectiveSelectInput,
        selectedExercises,
        selectedExit,
        selectedIncompleteRecap.group_id,
        shouldShowAdminTypes,
        topics,
    ]);

    return (
        <SteppedForm
            formSteps={formSteps}
            onClose={onClose}
            isLoadingSubmission={isUpdatingObjectives || isSubmittingRecap}
        />
    );
}

export default SubmitRecap;
