import React, { useCallback, useMemo, useState } from 'react';

import CaretDown from 'assets/caret-down.svg';

import { Forms, Fonts, Spacing } from 'styles/theme';
import {
    DropdownArrowWrapper,
    DropdownInnerWrapper,
    DropdownOuterWrapper,
    OptionWrapper,
    SectionHeader,
    SectionWrapper,
    SelectedOptionText,
    ToggleDropdownTarget,
} from 'styles/multiSectionDropdown';

interface Option {
    value: string;
    label: string | number;
    isDisabled?: boolean;
}

interface Section {
    headerText?: string;
    options: Array<Option>;
}

interface MultiSelectDropdownProps {
    selectedOptionIndex?: number;
    sections: Array<Section>;
    onSelectOption: (optionValue: string) => void;
    onOpenDropdown?: () => void;
}

function MultiSelectDropdown(props: MultiSelectDropdownProps) {
    const { selectedOptionIndex, sections, onSelectOption, onOpenDropdown } =
        props;

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const selectedOption: Option | null = useMemo(() => {
        let dropdownOptionsCount = 0;
        sections.forEach(
            (section) => (dropdownOptionsCount += section.options.length)
        );

        let optionToReturn: Option | null = null;
        for (let i = 0; i < dropdownOptionsCount; i++) {
            sections.some((section) =>
                section.options.some((option) => {
                    if (i === selectedOptionIndex) {
                        optionToReturn = option;
                        return true;
                    }
                    i++;
                })
            );
        }
        return optionToReturn;
    }, [sections, selectedOptionIndex]);

    const onToggleDropdown = useCallback(() => {
        if (!isDropdownOpen) {
            onOpenDropdown?.();
        }
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen, onOpenDropdown]);

    const onClickOption = useCallback(
        (value: string, isDisabled: boolean) => () => {
            if (isDisabled) {
                return;
            }

            onSelectOption(value);
            setIsDropdownOpen(false);
        },
        [onSelectOption]
    );

    return (
        <DropdownOuterWrapper>
            <DropdownInnerWrapper>
                <ToggleDropdownTarget onClick={onToggleDropdown}>
                    <SelectedOptionText>
                        {/* Not sure what's going on here, it may be a TypeScript bug since */}
                        {/* selectionOption is typed, but is being interpreted as "never" */}
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {selectedOption?.label ?? ''}
                    </SelectedOptionText>
                    <DropdownArrowWrapper>
                        <Forms.DropdownArrow
                            src={CaretDown}
                            $isFlipped={isDropdownOpen}
                        />
                    </DropdownArrowWrapper>
                </ToggleDropdownTarget>
                {isDropdownOpen
                    ? sections.map((section) => {
                          let currentOptionIndex = 0;
                          const sectionNodeToRender = (
                              <SectionWrapper>
                                  {section.headerText != null ? (
                                      <>
                                          <SectionHeader>
                                              {section.headerText}
                                          </SectionHeader>
                                          <Spacing.Divider />
                                      </>
                                  ) : null}
                                  {section.options.map((option) => {
                                      let isSelected = false;
                                      if (
                                          currentOptionIndex ===
                                          selectedOptionIndex
                                      ) {
                                          isSelected = true;
                                      }
                                      currentOptionIndex++;
                                      return (
                                          <OptionWrapper
                                              key={option.value}
                                              onClick={onClickOption(
                                                  option.value,
                                                  option.isDisabled ?? false
                                              )}
                                              $isDisabled={
                                                  option.isDisabled ?? false
                                              }
                                          >
                                              <Fonts.BodyText
                                                  $color={
                                                      option.isDisabled ?? false
                                                          ? 'grey'
                                                          : isSelected ?? false
                                                          ? 'orange'
                                                          : 'primary'
                                                  }
                                              >
                                                  {option.label}
                                              </Fonts.BodyText>
                                          </OptionWrapper>
                                      );
                                  })}
                              </SectionWrapper>
                          );
                          return sectionNodeToRender;
                      })
                    : null}
            </DropdownInnerWrapper>
        </DropdownOuterWrapper>
    );
}

export default MultiSelectDropdown;
